<template>
	<div class="generatechart" data-aos="slide-down" data-aos-delay="0">
		<div v-if="showData.T_v3d==''">
			<noData></noData>
			<div style="color: #ccc;font-size: 12px;text-align: center;">哎呀，没有视图哦</div>
		</div>
		<div v-else style="width: 100%;height: 100%;display: flex;">
			<iframe style="width: 100%; flex:1;" :src="`https://cold.coldbaozhida.com/Cold_DataV/?T_key=${showFirm.plan}&T_mode=2`" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex"
	export default {
		computed:mapGetters(["showData","showFirm"]),
		data() {
			return {
				token:this.$store.state.userInfo.token
			}
		},
		created() {
			////console.log('luj',this.showData.T_plan,this.token)
			// window.open('https://osscold.baozhida.cn/Tool/DataTool.htm','_blank')
		},
		mounted() {
		}
	}
</script>

<style lang="scss">
	.generatechart{
		height: 100vh;
	}
</style>
